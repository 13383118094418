import Swal from 'sweetalert2';
import Rails from "@rails/ujs";

export default function(message,element){
    let success_message = $(element).data("success-message")
    Swal.fire({
        title: message,
        icon: 'info',
        html: '',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel'
    }).then((result) => {
        if (result.value) {
            // Call the original action, but prevent infinite loop
            // Removes the sweet-alert confirm code, triggers the original action, then restores the confirm code.
            let old = Rails.confirm
            Rails.confirm = function () {
                return true
            }
            element.click();
            Rails.confirm = old
            Swal.fire(
                'Okay!',
                success_message,
                'success'
            )
        }
    })
}