// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs"
import Swal from 'sweetalert2';
import SweetHandler from './confirmation-handler'
Rails.confirm = SweetHandler
Rails.start()

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")

import 'bootstrap'
import './src/application.scss'
import './src/admin.js'
import './src/shared.js'
import flatpickr from "flatpickr"
import "@fortawesome/fontawesome-free/js/all";

// Uncomment to copy all static images under ../images to the output folder and reference them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>) or the `imagePath` JavaScript helper below.
window.jQuery = $;
window.$ = $;

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
