$( document).on('turbolinks:load', function() {
    flatpickr(".finish_time_picker",
        {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            defaultDate: Date.parse($(".finish_time_picker").val()) ? "12:00" : $(".finish_time_picker").val(),
            time_24hr: true
        });
      flatpickr(".start_time_picker",
        {
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            defaultDate: Date.parse($(".start_time_picker").val()) ? "10:00" : $(".start_time_picker").val(),
            time_24hr: true
        });
      flatpickr(".datepicker", {
          altInput: true
      });
})